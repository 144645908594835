<template>
  <div>
    <b-modal
      id="modal-accept-id"
      ref="modalAcceptId"
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-disabled
      cancel-title="Close"
      centered
      title="Identiteitsbewijs Valideren"
      :hide-footer="true"
      no-close-on-backdrop
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(handleValidateIdentificationFiles)"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="bsn"
                rules="required|integer"
              >
                <b-form-group
                  label="BSN Nummer"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="bsn"
                    v-model="bsn"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Accepteren
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-pdf-viewer"
      ref="modalPdfViewer"
      title=""
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-title="Close"
      centered
      :hide-footer="true"
      size="lg"
    >
      <pdf-viewer />
    </b-modal>
    <b-modal
      id="modal-change-paycheck-period-invoice"
      ref="modalChangePaycheckPeriodInvoice"
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-disabled
      cancel-title="Close"
      centered
      title="Verloningsperiode wijzigen"
      :hide-footer="true"
      no-close-on-backdrop
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(handleChangePaycheckPeriode)"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="Verloningsperiode"
                rules="required"
              >
                <b-form-group
                  :state="getValidationState(validationContext)"
                >
                  <label>Selecteer een verloningsperiode</label>
                  <v-select
                    id="paycheckPeriodSelected"
                    v-model="paycheckPeriodSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="getPayPeriods()"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <b-spinner
                  v-if="showChangePaycheckPeriodSpinner"
                  small
                  class="mr-1"
                />
                Aanvragen
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-row class="match-height">
      <b-col
        cols="12"
        md="8"
        class="d-flex justify-content-between flex-column"
      >
        <b-overlay
          :show="showOverlay"
          rounded="sm"
        >
          <b-card
            title="Gegevens"
          >
            <!-- User Info: Input Fields -->
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <b-form
                @submit.prevent="handleSubmit(update(freelancerData))"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Voornaam"
                      rules=""
                    >
                      <b-form-group
                        label="Voornaam"
                        label-for="firstName"
                      >
                        <b-form-input
                          id="firstName"
                          v-model="freelancerData.firstName"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="middleName"
                      rules=""
                    >
                      <b-form-group
                        label="Tussenvoegsel"
                        label-for="middleName"
                      >
                        <b-form-input
                          id="middleName"
                          v-model="freelancerData.middleName"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Achternaam"
                      rules=""
                    >
                      <b-form-group
                        label="Achternaam"
                        label-for="lastName"
                      >
                        <b-form-input
                          id="lastName"
                          v-model="freelancerData.lastName"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                      cols="12"
                      md="2"
                  >
                    <validation-provider
                        #default="validationContext"
                        rules=""
                    >
                      <b-form-group
                          label="Geslacht"
                      >
                        <v-select
                            v-model="freelancerData.gender"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="[
                            { label: 'Man', value: 'm' },
                            { label: 'Vrouw', value: 'f' },
                          ]"
                            :reduce="val => val.value"
                            :clearable="false"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="E-mailadres"
                      rules=""
                    >
                      <b-form-group
                        label="E-mailadres"
                        label-for="email"
                      >
                        <b-form-input
                          id="email"
                          v-model="freelancerData.email"
                          :state="getValidationState(validationContext)"
                          disabled="disabled"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Telefoon"
                      rules=""
                    >
                      <b-form-group
                        label="Telefoon"
                        label-for="phone"
                      >
                        <b-form-input
                          id="phone"
                          v-model="freelancerData.phone"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      rules=""
                    >
                      <b-form-group
                        label="Geboortedatum"
                      >
                        <b-form-input
                          v-model="freelancerData.birthdate"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      rules=""
                    >
                      <b-form-group
                        label="IBAN"
                      >
                        <b-form-input
                          v-model="freelancerData.iban"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      rules=""
                    >
                      <b-form-group
                        label="Straat"
                      >
                        <b-form-input
                          v-model="freelancerData.street"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <validation-provider
                      #default="validationContext"
                      rules=""
                    >
                      <b-form-group
                        label="Huisnummer"
                      >
                        <b-form-input
                          v-model="freelancerData.houseNumber"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <validation-provider
                      #default="validationContext"
                      rules=""
                    >
                      <b-form-group
                        label="Huisnummertoevoeging"
                      >
                        <b-form-input
                          v-model="freelancerData.houseNumberAddition"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      rules=""
                    >
                      <b-form-group
                        label="Postcode"
                      >
                        <b-form-input
                          v-model="freelancerData.postalCode"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      rules=""
                    >
                      <b-form-group
                        label="Plaats"
                      >
                        <b-form-input
                          v-model="freelancerData.city"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      rules=""
                    >
                      <b-form-group
                        label="Geboortedatum"
                      >
                        <b-form-input
                          v-model="freelancerData.birthdate"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="bsnNumber"
                      rules="required|integer"
                    >
                      <b-form-group
                        label="BSN Nummer"
                        :state="getValidationState(validationContext)"
                      >
                        <b-form-input
                          id="bsnNumber"
                          v-model="freelancerData.bsnNumber"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                      cols="12"
                      md="2"
                  >
                    <validation-provider
                        #default="validationContext"
                        rules=""
                    >
                      <b-form-group
                          label="Totale verificatie"
                      >
                        <v-select
                            v-model="freelancerData.isVerified"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="[
                            { label: 'Ja', value: 1 },
                            { label: 'Nee', value: 0 },
                          ]"
                            :reduce="val => val.value"
                            :clearable="false"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                      cols="12"
                      md="2"
                  >
                    <validation-provider
                        #default="validationContext"
                        rules=""
                    >
                      <b-form-group
                          label="ID Verificatie"
                      >
                        <v-select
                            v-model="freelancerData.idVerified"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="[
                            { label: 'Ja', value: 1 },
                            { label: 'Nee', value: 0 },
                          ]"
                            :reduce="val => val.value"
                            :clearable="false"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                      cols="12"
                      md="2"
                  >
                    <validation-provider
                        #default="validationContext"
                        rules=""
                    >
                      <b-form-group
                          label="ID Uploaded"
                      >
                        <v-select
                            v-model="freelancerData.idUploaded"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="[
                            { label: 'Ja', value: 1 },
                            { label: 'Nee', value: 0 },
                          ]"
                            :reduce="val => val.value"
                            :clearable="false"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                      cols="12"
                      md="2"
                  >
                    <validation-provider
                        #default="validationContext"
                        rules=""
                    >
                      <b-form-group
                          label="iDin verificatie"
                      >
                        <v-select
                            v-model="freelancerData.idinVerified"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="[
                            { label: 'Ja', value: 1 },
                            { label: 'Nee', value: 0 },
                          ]"
                            :reduce="val => val.value"
                            :clearable="false"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                      cols="12"
                      md="2"
                  >
                    <validation-provider
                        #default="validationContext"
                        rules=""
                    >
                      <b-form-group
                          label="OVK Getekend"
                      >
                        <v-select
                            v-model="freelancerData.agreementSigned"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="[
                            { label: 'Ja', value: 1 },
                            { label: 'Nee', value: 0 },
                          ]"
                            :reduce="val => val.value"
                            :clearable="false"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                      cols="12"
                      md="2"
                  >
                    <validation-provider
                        #default="validationContext"
                        rules=""
                    >
                      <b-form-group
                          label="TFA Ingeschakeld"
                      >
                        <v-select
                            v-model="freelancerData.tfaEnabled"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="[
                            { label: 'Ja', value: 1 },
                            { label: 'Nee', value: 0 },
                          ]"
                            :reduce="val => val.value"
                            :clearable="false"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                      cols="12"
                      md="2"
                  >
                    <validation-provider
                        #default="validationContext"
                        rules=""
                    >
                      <b-form-group
                          label="Sociale premies"
                      >
                        <v-select
                            v-model="freelancerData.hasSocialCharges"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="[
                            { label: 'Ja', value: 1 },
                            { label: 'Nee', value: 0 },
                          ]"
                            :reduce="val => val.value"
                            :clearable="false"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                      cols="12"
                      md="2"
                  >
                    <validation-provider
                        #default="validationContext"
                        rules=""
                    >
                      <b-form-group
                          label="LH Korting"
                      >
                        <v-select
                            v-model="freelancerData.hasLoonHeffingDiscount"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="[
                            { label: 'Ja', value: 1 },
                            { label: 'Nee', value: 0 },
                          ]"
                            :reduce="val => val.value"
                            :clearable="false"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                      cols="12"
                      md="2"
                  >
                    <validation-provider
                        #default="validationContext"
                        rules=""
                    >
                      <b-form-group
                          label="Trengo Gekoppeld"
                      >
                        <v-select
                            v-model="freelancerData.hasTrengo"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="[
                            { label: 'Ja', value: 1 },
                            { label: 'Nee', value: 0 },
                          ]"
                            :reduce="val => val.value"
                            :clearable="false"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                      cols="12"
                      md="4"
                  >
                    <validation-provider
                        #default="validationContext"
                        rules=""
                    >
                      <b-form-group
                          label="Trengo Profile ID"
                      >
                        <b-form-input
                            v-model="freelancerData.trengoID"
                            :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="6"-->
                  <!--                  >-->
                  <!--                    <validation-provider-->
                  <!--                      #default="validationContext"-->
                  <!--                      name="externalIdentifier"-->
                  <!--                      rules=""-->
                  <!--                    >-->
                  <!--                      <b-form-group-->
                  <!--                        label="Kenmerk"-->
                  <!--                        label-for="externalIdentifier"-->
                  <!--                      >-->
                  <!--                        <b-form-input-->
                  <!--                          id="externalIdentifier"-->
                  <!--                          v-model="freelancerData.link.externalIdentifier"-->
                  <!--                          :state="getValidationState(validationContext)"-->
                  <!--                        />-->
                  <!--                        <b-form-invalid-feedback :state="getValidationState(validationContext)">-->
                  <!--                          {{ validationContext.errors[0] }}-->
                  <!--                        </b-form-invalid-feedback>-->
                  <!--                      </b-form-group>-->
                  <!--                    </validation-provider>-->
                  <!--                  </b-col>-->
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                  >-->
                  <!--                    <b-form-group>-->
                  <!--                      <b-form-checkbox-->
                  <!--                        name="companyPaysFee"-->
                  <!--                        v-model="freelancerData.link.companyPaysFee"-->
                  <!--                        :value="1"-->
                  <!--                        :unchecked-value="0"-->
                  <!--                      >-->
                  <!--                        Bedrijf betaald de handeling fee-->
                  <!--                      </b-form-checkbox>-->
                  <!--                    </b-form-group>-->
                  <!--                  </b-col>-->
                </b-row>
                <b-row
                  class="mt-2"
                >
                  <b-col
                    cols="12"
                    class="text-right"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      type="submit"
                    >
                      <span>Opslaan</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col
        class="d-flex justify-content-between flex-column"
        cols="12"
        md="4"
      >
        <b-card
          title="Identiteitsbewijs"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-table
                :items="identificationFiles"
                :fields="tableIdentificationFilesColumns"
                striped
                responsive
                show-empty
                empty-text="Geen overeenkomende records gevonden"
                class="position-relative mb-4"
              >
                <template #cell(status)="data">
                  <b-badge
                    :key="data.id"
                    :variant="resolveIdStatusVariant(freelancerData.idVerified).variant"
                  >
                    {{ resolveIdStatusVariant(freelancerData.idVerified).status }}
                  </b-badge>
                </template>
                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <b-link
                      :href="data.item.url"
                      target="_blank"
                    >
                      <feather-icon
                        :id="`receipt-row-${data.item.id}-download-icon`"
                        class="cursor-pointer mx-1"
                        icon="DownloadIcon"
                        size="16"
                        style="color:#82868b"
                      />
                      <b-tooltip
                        :target="`receipt-row-${data.item.id}-download-icon`"
                        placement="right"
                      >
                        <p class="mb-0">
                          downloaden
                        </p>
                      </b-tooltip>
                    </b-link>
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                v-if="(!freelancerData.idVerified || freelancerData.idVerified === 0) && (freelancerData.idUploaded && freelancerData.idUploaded === 1)"
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="validateIdentificationFiles"
              >
                <span>Identiteitsbewijs Valideren</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-overlay
          :show="showPaySlipsOverlay"
          rounded="sm"
        >
          <b-card
            title="Facturen"
          >
            <b-table
              :items="invoices"
              :fields="tableInvoicesColumns"
              striped
              responsive
              show-empty
              empty-text="Geen overeenkomende records gevonden"
              class="position-relative mb-4"
            >
              <!-- Column: Id -->
              <template #cell(id)="data">
                #{{ data.value }}
              </template>

              <template #cell(status)="data">
                <b-badge :variant="resolveTypeVariant(data.value).variant">
                  {{ resolveTypeVariant(data.value).status }}
                </b-badge>
              </template>

              <template #cell(companyID)="data">
                {{ data.item.company.name }}
              </template>

              <template #cell(totalAmount)="data">
                &euro; {{
                  parseFloat(data.value)
                    .toFixed(2)
                }}
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <!--                  <b-dropdown-item-->
                  <!--                    :id="`approve-row-${data.item.id}-invoice`"-->
                  <!--                    v-if="data.item.status === 11 && profileData.type === 1 || data.item.status === 12 && profileData.type === 2"-->
                  <!--                    @click="confirmApproveDeclineInvoice(data.item.status, 1, data.item.id)"-->
                  <!--                  >-->
                  <!--                    <feather-icon-->
                  <!--                      :id="`approve-row-${data.item.id}-invoice-icon`"-->
                  <!--                      class="cursor-pointer"-->
                  <!--                      icon="CheckCircleIcon"-->
                  <!--                      size="16"-->
                  <!--                      style="color: #29625b;"-->
                  <!--                    />-->
                  <!--                    Accepteren-->
                  <!--                  </b-dropdown-item>-->
                  <!--                  <b-dropdown-item-->
                  <!--                    :id="`decline-row-${data.item.id}-invoice`"-->
                  <!--                    v-if="data.item.status === 11 && profileData.type === 1 || data.item.status === 12 && profileData.type === 2"-->
                  <!--                    @click="confirmApproveDeclineInvoice(data.item.status, 0, data.item.id)"-->
                  <!--                  >-->
                  <!--                    <feather-icon-->
                  <!--                      :id="`decline-row-${data.item.id}-invoice-icon`"-->
                  <!--                      class="cursor-pointer"-->
                  <!--                      icon="XCircleIcon"-->
                  <!--                      size="16"-->
                  <!--                      style="color: #29625b;"-->
                  <!--                    />-->
                  <!--                    Afkeuren-->
                  <!--                  </b-dropdown-item>-->
                  <b-dropdown-item
                    :id="`pdf-row-${data.item.id}-preview`"
                    @click="viewDocument(data.item)"
                  >
                    <feather-icon
                      :id="`pdf-row-${data.item.id}-preview-icon`"
                      class="cursor-pointer"
                      icon="EyeIcon"
                      size="16"
                      style="color: #29625b;"
                    />
                    Bekijken
                  </b-dropdown-item>
                  <b-dropdown-item
                    :id="`invoices-row-${data.item.id}-download`"
                    :href="`${axiosDefaultConfig.backendEndPoint}invoices/${data.item.id}/pdf?token=${data.item.pdfToken}`"
                    target="_blank"
                  >
                    <feather-icon
                      :id="`invoices-row-${data.item.id}-download-icon`"
                      class="cursor-pointer"
                      icon="DownloadIcon"
                      size="16"
                    />
                    Downloaden
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col
        cols="12"
        md="12"
        class="d-flex justify-content-between flex-column"
      >
        <b-overlay
          :show="showAgreementOverlay"
          rounded="sm"
        >
          <b-card
            title="Overeenkomsten"
          >
            <b-row class="mt-0 mb-2">
              <b-col
                cols="12"
                md="12"
                class="d-flex align-items-center justify-content-end"
              >
                <!--                <b-button-->
                <!--                  variant="secondary"-->
                <!--                  @click="$refs.modalAgreement.show()"-->
                <!--                >-->
                <!--                  <span class="text-nowrap">Nieuwe overeenkomst aanmaken</span>-->
                <!--                </b-button>-->
              </b-col>
            </b-row>
            <b-table
              :items="agreements"
              :fields="tableAgreementsColumns"
              striped
              responsive
              show-empty
              empty-text="Geen overeenkomst(en) gevonden"
              class="position-relative"
            >
              <!-- Column: Id -->
              <template #cell(id)="data">
                #{{ data.value }}
              </template>
              <template #cell(status)="data">
                {{ resolveTypeAgreementVariant(data.value).status }}
              </template>
              <template #cell(freelancerConfirmTimestamp)="data">
                {{ data.value ? formatDateTime(data.value) : 'In afwachting' }}
              </template>
              <template #cell(endDate)="data">
                {{ data.value ? data.value : 'Onbepaalde tijd' }}
              </template>
              <template #cell(actions)="data">
                <div class="text-nowrap">
                  <feather-icon
                    :id="`pdf-row-${data.item.id}-preview-icon`"
                    class="cursor-pointer mx-1"
                    icon="EyeIcon"
                    size="16"
                    style="color:#fdb813"
                    @click="viewAgreement(data.item)"
                  />
                  <b-link
                    :href="`${axiosDefaultConfig.backendEndPoint}agreements/${data.item.id}/pdf?token=${data.item.pdfToken}`"
                    target="_blank"
                  >
                    <feather-icon
                      :id="`invoice-row-${data.item.id}-download-icon`"
                      class="cursor-pointer"
                      icon="DownloadIcon"
                      size="16"
                      style="color:#82868b"
                    />
                  </b-link>
                  <feather-icon
                    v-if="userData.type === 1 && data.item.status == 5 || userData.type === 2 && data.item.status == 6"
                    :id="`agreement-row-${data.item.id}-approval-icon`"
                    class="cursor-pointer ml-1"
                    icon="CheckCircleIcon"
                    size="16"
                    style="color:rgb(40,199,111);"
                    @click="confirmAgreementApproveText(data.item.id)"
                  />
                  <feather-icon
                    v-if="userData.type === 1 && data.item.status == 5 || userData.type === 2 && data.item.status == 6"
                    :id="`agreement-row-${data.item.id}-disapprive-icon`"
                    class="cursor-pointer ml-1"
                    icon="XCircleIcon"
                    size="16"
                    style="color:rgb(234,84,85) !important;"
                    @click="confirmAgreementDisapproveText(data.item.id)"
                  />
                </div>
              </template>
            </b-table>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-card
          title=""
        >
          <b-row>
            <b-col
              cols="6"
              class="text-left"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                @click="$router.go(-1)"
              >
                <span>terug</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BOverlay,
  BRow,
  BTable,
  BDropdown,
  BDropdownItem,
  BBadge,
  BSpinner,
  BLink,
  BTooltip,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import formValidation from '@core/comp-functions/forms/form-validation'
import { formatDateTime, formatYearMothString, getPayPeriods } from '@core/utils/utils'
import 'flatpickr/dist/flatpickr.css'
import {
  email,
  required,
  date,
  integer,
  between,
} from '@core/utils/validations/validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import freelancersStoreModule from '@/views/pages/freelancers/freelancersStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useFreelancersList from '@/views/pages/freelancers/useFreelancersList'
import { heightTransition } from '@core/mixins/ui/transition'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'
import PdfViewer from '@/views/components/pdf-viewer/PdfViewer.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BTable,
    BOverlay,
    vSelect,
    BDropdown,
    BDropdownItem,
    BBadge,
    PdfViewer,
    BSpinner,
    BLink,
    BTooltip,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      required,
      email,
      date,
      integer,
      between,
      freelancerData: {
        idVerified: null,
        idURL1: null,
        idURL2: null,
        link: {
          externalIdentifier: null,
          companyPaysFee: 0,
        },
      },
      invoices: [],
      agreements: [],
      showOverlay: false,
      showPaySlipsOverlay: false,
      showAgreementOverlay: false,
      startDateConfig: {
        minDate: 'today',
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      endDateConfig: {
        minDate: 'today',
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      showAddAGreementSpinner: false,
      paycheckPeriodSelected: null,
      showChangePaycheckPeriodSpinner: false,
      userData: {},
      identificationFiles: [],
      bsn: null,
    }
  },
  beforeMount() {
    this.getFreelancer()
    this.fetchInvoices()
    this.fetchAgreements()
    this.userData = JSON.parse(localStorage.getItem('userData'))
  },
  methods: {
    update(data) {
      // eslint-disable-next-line no-param-reassign
      data.id = this.freelancerData.id
      store
        .dispatch('app-freelancer-edit/patchFreelancer', data)
        .then(() => {
          this.getFreelancer()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Update verloonopties',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het aanpassen van de verloonopties. Probeer het nogmaals.',
            },
          })
        })
    },
    validateIdentificationFiles() {
      this.$swal({
        title: 'Weet je het zeker?',
        text: 'U kunt dit niet meer terugdraaien!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, Identiteitsbewijs accepteren!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.$refs.modalAcceptId.show()
          }
        })
    },
    handleValidateIdentificationFiles() {
      store.dispatch('app-freelancer-edit/patchFreelancer', {
        id: this.freelancerData.id,
        idVerified: 1,
        bsnNumber: this.bsn,
      })
        .then(() => {
          this.$refs.modalAcceptId.hide()
          this.getFreelancer()
          this.$swal({
            icon: 'success',
            title: 'Identiteitsbewijs',
            text: 'Identiteitsbewijs is gevalideerd.',
          })
        })
        .catch(() => {
          this.$refs.modalAcceptId.hide()
          this.$swal({
            icon: 'error',
            title: 'Identiteitsbewijs',
            text: 'Er is een fout opgetreden bij het aanpassen van de data. Probeer het later nog een keer.',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    confirmApproveDeclineInvoice(status, type, id) {
      this.$swal({
        title: 'Weet je het zeker?',
        text: 'U kunt dit niet meer terugdraaien!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, factuur accepteren!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.approveInvoice(status, type, id)
          }
        })
    },
    approveInvoice(status, type, id) {
      const acceptData = { id }

      if (status === 11 && this.profileData.type === 1) {
        if (type === 1) {
          acceptData.isFreelancerApproved = 1
        } else if (type === 0) {
          acceptData.isFreelancerApproved = -1
        }
      }

      if (status === 12 && this.profileData.type === 2) {
        if (type === 1) {
          acceptData.isCompanyApproved = 1
        } else if (type === 0) {
          acceptData.isCompanyApproved = -1
        }
      }

      store
        .dispatch('app-freelancer-edit/approveInvoice', acceptData)
        .then(() => {
          this.refetchData()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Factuur accepteren',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het opslaan van de data. Probeer het nogmaals.',
            },
          })
        })
    },
    showModalPaycheckPeriode(id) {
      this.id = id
      this.$refs.modalChangePaycheckPeriodInvoice.show()
    },
    handleChangePaycheckPeriode() {
      this.showChangePaycheckPeriodSpinner = true
      const period = this.getPayPeriods()
        .filter(b => b.value === this.paycheckPeriodSelected)[0]

      store
        .dispatch('app-freelancer-edit/patchInvoice', {
          paycheckPeriod: period.value,
          id: this.id,
        })
        .then(() => {
          this.showChangePaycheckPeriodSpinner = false
          this.$refs.modalChangePaycheckPeriodInvoice.hide()
          this.id = null
          this.refetchData()
        })
        .catch(() => {
          this.showChangePaycheckPeriodSpinner = false
          this.$refs.modalChangePaycheckPeriodInvoice.hide()
          this.id = null
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Verloningsperiode wijzigen',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het aanpassen van de verloningsperiode. Probeer het nogmaals.',
            },
          })
        })
    },
    updateFreelancer() {
      store.dispatch('app-freelancer-edit/updateFreelancer', {
        id: this.freelancerData.id,
        companyPaysFee: this.freelancerData.link.companyPaysFee,
        externalIdentifier: this.freelancerData.link.externalIdentifier,
      })
        .then(() => {
          this.$router.go(-1)
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            title: 'Freelancer informatie',
            text: 'Er is een fout opgetreden bij het aanpassen van de data. Probeer het later nog een keer.',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    getFreelancer() {
      this.showOverlay = true
      store.dispatch('app-freelancer-edit/fetchFreelancer', { id: router.currentRoute.params.id })
        .then(response => {
          this.showOverlay = false
          this.freelancerData = response.data
          this.identificationFiles = []

          if (this.freelancerData.idURL1) {
            this.identificationFiles.push(
              {
                id: this.freelancerData.idFile1,
                name: 'Identiteitsbewijs voorkant',
                url: this.freelancerData.idURL1,
                status: this.freelancerData.idVerified,
              },
            )
          }
          if (this.freelancerData.idURL2) {
            this.identificationFiles.push(
              {
                id: this.freelancerData.idFile2,
                name: 'Identiteitsbewijs achterkant',
                url: this.freelancerData.idURL2,
                status: this.freelancerData.idVerified,
              },
            )
          }
        })
        .catch(() => {
          this.showOverlay = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ophalen freelancer informatie',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer later het nog een keer.',
            },
          })
        })
    },
    fetchInvoices() {
      this.showPaySlipsOverlay = true
      store
        .dispatch('app-freelancer-edit/fetchInvoices', { id: router.currentRoute.params.id }, {
          sortDesc: true,
        })
        .then(response => {
          this.showPaySlipsOverlay = false
          this.invoices = response.data.items
        })
        .catch(() => {
          this.showPaySlipsOverlay = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ophalen facturen',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
            },
          })
        })
    },
    fetchAgreements() {
      // this.showPaySlipsOverlay = true
      store
        .dispatch('app-freelancer-edit/fetchAgreements', router.currentRoute.params.id, {
          sortDesc: true,
        })
        .then(response => {
          // this.showPaySlipsOverlay = false
          this.agreements = response.data.items
        })
        .catch(() => {
          // this.showPaySlipsOverlay = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ophalen facturen',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
            },
          })
        })
    },
    viewDocument(data) {
      this.$store.commit('app/UPDATE_PDF_URL', `${axiosDefaultConfig.backendEndPoint}invoices/${data.id}/pdf?token=${data.pdfToken}`)
      this.$refs.modalPdfViewer.show()
    },
    viewAgreement(data) {
      this.$store.commit('app/UPDATE_PDF_URL', `${axiosDefaultConfig.backendEndPoint}agreements/${data.id}/pdf?token=${data.pdfToken}`)
      this.$refs.modalPdfViewer.show()
    },
  },
  setup() {
    const FREELANCER_EDIT_APP_STORE_MODULE_NAME = 'app-freelancer-edit'

    // Register module
    if (!store.hasModule(FREELANCER_EDIT_APP_STORE_MODULE_NAME)) store.registerModule(FREELANCER_EDIT_APP_STORE_MODULE_NAME, freelancersStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FREELANCER_EDIT_APP_STORE_MODULE_NAME)) store.unregisterModule(FREELANCER_EDIT_APP_STORE_MODULE_NAME)
    })

    const {
      tableInvoicesColumns,
      resolveTypeVariant,
      tableAgreementsColumns,
      agreementUnits,
      noticePeriods,
      sectorOptions,
      resolveTypeAgreementVariant,
      tableIdentificationFilesColumns,
      resolveIdStatusVariant,
    } = useFreelancersList()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      formatYearMothString,
      formatDateTime,
      tableInvoicesColumns,
      resolveTypeVariant,
      tableAgreementsColumns,
      agreementUnits,
      noticePeriods,
      sectorOptions,
      axiosDefaultConfig,
      getPayPeriods,
      resolveTypeAgreementVariant,
      tableIdentificationFilesColumns,
      resolveIdStatusVariant,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss" scoped>
.form-group.is-invalid {
  ::v-deep .form-control {
    border-color: #ea5455 !important;
  }
}
</style>
